import { Component, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent {
  passForm = new FormGroup(
    {
      password: new FormControl('', [Validators.required, validPassword]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    { validators: [passwordsMatch] }
  );

  private _next: Subject<string> = new Subject();
  @Output() next = this._next.asObservable();

  @Input() error: Subject<string | null> = new BehaviorSubject<string | null>(
    null
  );

  constructor() {}

  get password() {
    return this.passForm.get('password');
  }

  get isValid() {
    return (
      this.password?.valid && (this.password?.dirty || this.password?.touched)
    );
  }

  get lengthError() {
    return !this.isValid && this.password?.errors?.minlength;
  }

  get upperCaseError() {
    return !this.isValid && this.password?.errors?.uppercase;
  }

  get lowerCaseError() {
    return !this.isValid && this.password?.errors?.lowercase;
  }

  get numberError() {
    return !this.isValid && this.password?.errors?.digit;
  }

  get specialError() {
    return !this.isValid && this.password?.errors?.special;
  }

  get confirm() {
    return this.passForm.get('confirmPassword');
  }

  get mismatchError() {
    return (
      (this.confirm?.dirty || this.confirm?.touched) &&
      this.passForm.errors?.mismatch
    );
  }

  submit() {
    if (this.passForm.invalid) {
      console.log(this.passForm.errors);
      return;
    }
    this._next.next(this.passForm.get('password')?.value);
  }
}

// This should be matching what the ValidPasswordAttribute in C# is doing
const validPassword: ValidatorFn = (control: AbstractControl) => {
  const value = control.value;
  if (!value) {
    return null;
  }
  let issues: PasswordValidation = {};
  let wasAnIssue = false;
  if (typeof value !== 'string') {
    issues.type = true;
    wasAnIssue = true;
  }
  if (value.length < 12) {
    issues.minlength = true;
    wasAnIssue = true;
  }
  if (!value.match(/\d/g)) {
    issues.digit = true;
    wasAnIssue = true;
  }
  if (!value.match(/[a-z]/g)) {
    issues.lowercase = true;
    wasAnIssue = true;
  }
  if (!value.match(/[A-Z]/g)) {
    issues.uppercase = true;
    wasAnIssue = true;
  }
  // This must match what the API is doing as to not cause unwanted behavior
  if (
    !value.match(
      /[\~\`\!\@\#\$\%\^\&\*\(\)\-_\+\=\{\}\[\]\|\:\;\'"\<\>\,\.\?\\\/]/g
    )
  ) {
    issues.special = true;
    wasAnIssue = true;
  }
  return wasAnIssue ? issues : null;
};

interface PasswordValidation {
  type?: boolean;
  minlength?: boolean;
  digit?: boolean;
  lowercase?: boolean;
  uppercase?: boolean;
  special?: boolean;
}

const passwordsMatch: ValidatorFn = (control: AbstractControl) => {
  const original = control.get('password')?.value;
  const confirm = control.get('confirmPassword')?.value;
  if (!original || !confirm) {
    return null;
  }
  return original === confirm ? null : { mismatch: true };
};
