<form [formGroup]="pinForm" (ngSubmit)="submit()">
  <p>
    Please enter the 6 digit PIN communicated to your Synergi Representative.
  </p>
  <mat-form-field appearance="fill">
    <mat-label>PIN</mat-label>
    <input matInput formControlName="pin" required />
  </mat-form-field>
  <div>
    <button mat-raised-button type="submit" [disabled]="pinForm.invalid">
      Next
    </button>
  </div>
  <mat-error *ngIf="(error | async) !== null">{{ error | async }}</mat-error>
</form>
