import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationComponent } from './components/registration/registration.component';
import { PasswordResetComponent } from './components/passwordreset/passwordreset.component';
import { PageNotFoundComponent } from './components/pagenotfound/pagenotfound.component';

const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  // {
  //   path: 'app',
  //   loadChildren: () => import('./app.module').then((m) => m.AppModule),
  // },
  {
    path: 'registration',
    component: RegistrationComponent,
  },
  {
    path: 'passwordreset',
    component: PasswordResetComponent,
  },
  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
