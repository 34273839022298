<div class="loading-outer" *ngIf="(processing | async) === true">
  <div class="loading-inner">
    <p>Loading, please wait...</p>
    <div>
      <mat-spinner style="margin:0 auto;" [diameter]="40"></mat-spinner>
    </div>
  </div>
</div>

<div *ngIf="(passwordResetValidated | async) === false && (showForm | async) === false">
  <h2>{{(statusMessage | async)?.title}}</h2>
  <p>
    {{(statusMessage | async)?.message}}
  </p>
</div>

<div *ngIf="(passwordResetValidated | async) === true && (showForm | async) === true">
  <h2>{{(statusMessage | async)?.title}}</h2>
  <p>
    {{(statusMessage | async)?.message}}
  </p>
</div>

<div *ngIf="(showPasswordResetSuccessful | async) === true">
  <h2>Success</h2>
  <p>
    Your password has been updated. Click <a href="https://sftp.synergipartners.com">here</a> to be directed to the SFTP login screen.
  </p>
</div>

<div *ngIf="(showForm | async) === true">
  <form [formGroup]="passwordResetForm" (ngSubmit)="submit()">
    <p>
      Please create a password that will be used as credentials to our file
      transfer service.
    </p>
    <div class="form-body">
      <div class="form-fields">
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="confirmPassword"
            required
          />
        </mat-form-field>

      </div>
      <aside>
        <span>Your password must contain:</span>
        <ul>
          <li *ngIf="mismatchError">
            <mat-error>Passwords do not match.</mat-error>
          </li>
          <li [ngClass]="{ invalid: lengthError }">
            At least 12 characters long
          </li>
          <li [ngClass]="{ invalid: upperCaseError }">
            At least 1 uppercase letter
          </li>
          <li [ngClass]="{ invalid: lowerCaseError }">
            At least 1 lowercase letter
          </li>
          <li [ngClass]="{ invalid: numberError }">At least 1 number</li>
          <li [ngClass]="{ invalid: specialError }">
            At least 1 special character <br /><small
              >~`!@#$%^&*()-_=+{{ "{}" }}[]|:;'"&lt;&gt;,.?\/</small
            >
          </li>
        </ul>
      </aside>
    </div>
    <button mat-raised-button type="submit" [disabled]="passwordResetForm.invalid">
      Submit
    </button>
    <div class="error" *ngIf="(error | async) !== null">
      <mat-error >* {{ error | async }}</mat-error>
    </div>
  </form>
</div>
