<mat-stepper
      [orientation]="'vertical'"
      [linear]="true"
      #stepper
      *ngIf="(formStatus | async) === 'valid'"
    >

      <mat-step
        label="Enter PIN"
        [editable]="false"
        [completed]="pinVerified | async"
      >
        <app-pin
          (next)="onVerifyPin($event)"
          [error]="error"
        ></app-pin>
      </mat-step>

      <mat-step
        label="Create Password"
        [editable]="false"
        [completed]="passwordSent | async"
      >
        <app-password
          (next)="onSendPassword($event)"
          [error]="error"
        ></app-password>
      </mat-step>

      <mat-step label="Hang Tight" #hangTight>
        <app-last [passwordReset]="passwordReset | async"></app-last>
      </mat-step>
    </mat-stepper>
    <ng-container *ngIf="(formStatus | async) === 'notFound'">
      <div class="body">
        <h2>Not Found</h2>
        <p>Registration not found.</p>
      </div>
    </ng-container>
      <ng-container *ngIf="(formStatus | async) === 'error'">
      <div class="body">
        <h2>Sorry</h2>
        <p>
          Something went wrong on our end. Try again in a moment. If this error
          persists, please contact us.
        </p>
      </div></ng-container><ng-container *ngIf="(formStatus | async) === 'completed'">
      <div class="body">
        <h2>Completed</h2>
        <p>
          Registration has been completed. Instructions have been sent to your
          email. Contact us if you have any questions or issues.
        </p>
      </div></ng-container><ng-container *ngIf="(formStatus | async) === 'loading'">
      <div class="body">
        <h2>Loading</h2>
        <p>Please wait while we pull information for your registration.</p>
      </div></ng-container>

