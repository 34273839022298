<form [formGroup]="passForm" (ngSubmit)="submit()">
  <p>
    Please create a password that will be used as credentials to our file
    transfer service.
  </p>
  <div class="form-body">
    <div class="form-fields">
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" required />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          type="password"
          formControlName="confirmPassword"
          required
        />
      </mat-form-field>
      <mat-error *ngIf="mismatchError">Passwords do not match.</mat-error>
    </div>
    <aside>
      <span>Your password must contain:</span>
      <ul>
        <li [ngClass]="{ invalid: lengthError }">
          At least 12 characters long
        </li>
        <li [ngClass]="{ invalid: upperCaseError }">
          At least 1 uppercase letter
        </li>
        <li [ngClass]="{ invalid: lowerCaseError }">
          At least 1 lowercase letter
        </li>
        <li [ngClass]="{ invalid: numberError }">At least 1 number</li>
        <li [ngClass]="{ invalid: specialError }">
          At least 1 special character <br /><small
            >~`!@#$%^&*()-_=+{{ "{}" }}[]|:;'"&lt;&gt;,.?\/</small
          >
        </li>
      </ul>
    </aside>
  </div>
  <button mat-raised-button type="submit" [disabled]="passForm.invalid">
    Next
  </button>
  <mat-error *ngIf="(error | async) !== null">{{ error | async }}</mat-error>
</form>
