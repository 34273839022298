<p>
  <ng-container *ngIf="passwordReset === true; else accountCreate"
    >Your Synergi Partner's File Transfer account password is being reset. This
    account provides you with the ability to securely transfer files to use. We
    will email you when your account's password has been reset.</ng-container
  >
  <ng-template #accountCreate
    >Your Synergi Partner's File Transfer account needs to be created. This
    account provides you with the ability to securely transfer files to us. We
    will email you when the process is complete.</ng-template
  >
</p>
