/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientUserNameMappingModel } from '../models/client-user-name-mapping-model';
import { ProvisioningCompleteRequest } from '../models/provisioning-complete-request';
import { ProvisioningRequest } from '../models/provisioning-request';
import { ProvisioningTwoFactorCheckRequest } from '../models/provisioning-two-factor-check-request';
import { ProvisioningTwoFactorCheckResponse } from '../models/provisioning-two-factor-check-response';
import { ProvisioningTwoFactorSubmitRequest } from '../models/provisioning-two-factor-submit-request';
import { ProvisioningTwoFactorSubmitResponse } from '../models/provisioning-two-factor-submit-response';
import { ProvisioningValidateRequest } from '../models/provisioning-validate-request';
import { ValidateInitialEmailResponse } from '../models/validate-initial-email-response';
import { ValidatePasswordResetRequest } from '../models/validate-password-reset-request';
import { ValidatePasswordResetResponse } from '../models/validate-password-reset-response';
import { ResetPasswordRequest } from '../models/reset-password-request';
import { ResetPasswordResponse } from '../models/reset-password-response';

@Injectable({
  providedIn: 'root',
})
export class SftpProvisioningService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation complete
   */
  static readonly CompletePath = '/public/provisioning/complete';

  /**
   * Complete the provisionging process.
   *
   * Complete the provisionging process
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `complete()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  complete$Response(params: {
    body: ProvisioningCompleteRequest
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SftpProvisioningService.CompletePath, 'post');
    if (params) {
      rb.body(params.body, 'applicaton/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Complete the provisionging process.
   *
   * Complete the provisionging process
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `complete$Response()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  complete(params: {
    body: ProvisioningCompleteRequest
  }): Observable<string> {

    return this.complete$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation twoFactorCheck
   */
  static readonly TwoFactorCheckPath = '/public/provisioning/twofactor/check';

  /**
   * Check TwoFactor Code.
   *
   * Check TwoFactor Code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `twoFactorCheck()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  twoFactorCheck$Response(params: {
    body: ProvisioningTwoFactorCheckRequest
  }): Observable<StrictHttpResponse<ProvisioningTwoFactorCheckResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SftpProvisioningService.TwoFactorCheckPath, 'post');
    if (params) {
      rb.body(params.body, 'applicaton/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProvisioningTwoFactorCheckResponse>;
      })
    );
  }

  /**
   * Check TwoFactor Code.
   *
   * Check TwoFactor Code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `twoFactorCheck$Response()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  twoFactorCheck(params: {
    body: ProvisioningTwoFactorCheckRequest
  }): Observable<ProvisioningTwoFactorCheckResponse> {

    return this.twoFactorCheck$Response(params).pipe(
      map((r: StrictHttpResponse<ProvisioningTwoFactorCheckResponse>) => r.body as ProvisioningTwoFactorCheckResponse)
    );
  }

  /**
   * Path part for operation twoFactorSubmit
   */
  static readonly TwoFactorSubmitPath = '/public/provisioning/twofactor/submit';

  /**
   * Send TwoFactor Code.
   *
   * Send TwoFactor Code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `twoFactorSubmit()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  twoFactorSubmit$Response(params: {
    body: ProvisioningTwoFactorSubmitRequest
  }): Observable<StrictHttpResponse<ProvisioningTwoFactorSubmitResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SftpProvisioningService.TwoFactorSubmitPath, 'post');
    if (params) {
      rb.body(params.body, 'applicaton/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProvisioningTwoFactorSubmitResponse>;
      })
    );
  }

  /**
   * Send TwoFactor Code.
   *
   * Send TwoFactor Code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `twoFactorSubmit$Response()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  twoFactorSubmit(params: {
    body: ProvisioningTwoFactorSubmitRequest
  }): Observable<ProvisioningTwoFactorSubmitResponse> {

    return this.twoFactorSubmit$Response(params).pipe(
      map((r: StrictHttpResponse<ProvisioningTwoFactorSubmitResponse>) => r.body as ProvisioningTwoFactorSubmitResponse)
    );
  }

  /**
   * Path part for operation validate
   */
  static readonly ValidatePath = '/public/provisioning/validate';

  /**
   * Validate that the encryption is valid.
   *
   * Validate that the encryption is valid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validate()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  validate$Response(params: {
    body: ProvisioningValidateRequest
  }): Observable<StrictHttpResponse<ValidateInitialEmailResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SftpProvisioningService.ValidatePath, 'post');
    if (params) {
      rb.body(params.body, 'applicaton/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidateInitialEmailResponse>;
      })
    );
  }

  /**
   * Validate that the encryption is valid.
   *
   * Validate that the encryption is valid
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validate$Response()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  validate(params: {
    body: ProvisioningValidateRequest
  }): Observable<ValidateInitialEmailResponse> {

    return this.validate$Response(params).pipe(
      map((r: StrictHttpResponse<ValidateInitialEmailResponse>) => r.body as ValidateInitialEmailResponse)
    );
  }

  /**
   * Path part for operation validate
   */
  static readonly ValidatePasswordResetPath = '/public/provisioning/passwordreset/validate';

  /**
   * Validate that the password reset validate request is valid.
   *
   * Validate that the password reset validate request is valid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validate()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  validatePasswordReset$Response(params: {
    body: ValidatePasswordResetRequest
  }): Observable<StrictHttpResponse<ValidatePasswordResetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SftpProvisioningService.ValidatePasswordResetPath, 'post');
    if (params) {
      rb.body(params.body, 'applicaton/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidatePasswordResetResponse>;
      })
    );
  }

  /**
   * Validate that the password reset validate request is valid
   *
   * Validate that the password reset validate request is valid
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validate$Response()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  validatePasswordReset(params: {
    body: ValidatePasswordResetRequest
  }): Observable<ValidatePasswordResetResponse> {

    return this.validatePasswordReset$Response(params).pipe(
      map((r: StrictHttpResponse<ValidatePasswordResetResponse>) => r.body as ValidatePasswordResetResponse)
    );
  }

  /**
   * Path part for operation validate
   */
  static readonly PasswordResetPath = '/public/provisioning/passwordreset';

  /**
   * Validate that the password reset validate request is valid.
   *
   * Validate that the password reset validate request is valid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validate()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  passwordReset$Response(params: {
    body: ResetPasswordRequest
  }): Observable<StrictHttpResponse<ResetPasswordResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SftpProvisioningService.PasswordResetPath, 'post');
    if (params) {
      rb.body(params.body, 'applicaton/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResetPasswordResponse>;
      })
    );
  }

  /**
   * Validate that the password reset validate request is valid
   *
   * Validate that the password reset validate request is valid
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validate$Response()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  passwordReset(params: {
    body: ResetPasswordRequest
  }): Observable<ResetPasswordResponse> {

    return this.passwordReset$Response(params).pipe(
      map((r: StrictHttpResponse<ResetPasswordResponse>) => r.body as ResetPasswordResponse)
    );
  }

  /**
   * Path part for operation get
   */
  static readonly GetPath = '/private/provisioning/{id}';

  /**
   * Get Information about provisioning.
   *
   * Get Information about provisioning
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: {

    /**
     * The EntityId to get provisioning information for.
     */
    id: string;
  }): Observable<StrictHttpResponse<ClientUserNameMappingModel>> {

    const rb = new RequestBuilder(this.rootUrl, SftpProvisioningService.GetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientUserNameMappingModel>;
      })
    );
  }

  /**
   * Get Information about provisioning.
   *
   * Get Information about provisioning
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: {

    /**
     * The EntityId to get provisioning information for.
     */
    id: string;
  }): Observable<ClientUserNameMappingModel> {

    return this.get$Response(params).pipe(
      map((r: StrictHttpResponse<ClientUserNameMappingModel>) => r.body as ClientUserNameMappingModel)
    );
  }

  /**
   * Path part for operation provision
   */
  static readonly ProvisionPath = '/private/provisioning';

  /**
   * Start the provisionging registration process.
   *
   * Start the provisionging registration process
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `provision()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  provision$Response(params: {
    body: ProvisioningRequest
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SftpProvisioningService.ProvisionPath, 'post');
    if (params) {
      rb.body(params.body, 'applicaton/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Start the provisionging registration process.
   *
   * Start the provisionging registration process
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `provision$Response()` instead.
   *
   * This method sends `applicaton/json` and handles request body of type `applicaton/json`.
   */
  provision(params: {
    body: ProvisioningRequest
  }): Observable<string> {

    return this.provision$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
