import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { beANumber } from 'src/app/validators/number';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss']
})
export class PinComponent implements OnDestroy {
  pinForm = new FormGroup({
    pin: new FormControl('', [Validators.required, beANumber]),
  });

  @Input() method: string | undefined = 'text/call';
  @Input() error: Observable<string | null> = new BehaviorSubject<
    string | null
  >(null);

  private _next: Subject<string> = new Subject();
  @Output() next = this._next.asObservable();

  private $unsubscribe: Subject<boolean> = new Subject();

  constructor() {}

  ngOnDestroy() {
    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }

  submit() {
    if (this.pinForm.invalid) {
      return;
    }
    this._next.next(this.pinForm.get('pin')?.value);
  }
}
