import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subject, catchError, of, take } from 'rxjs';
import { SftpProvisioningService } from 'src/app/api-client/services';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  processing: Subject<boolean> = new BehaviorSubject<boolean>(false);
  passwordResetValidated: Subject<boolean> = new BehaviorSubject<boolean>(false);
  showForm: Subject<boolean> = new BehaviorSubject<boolean>(false);
  showPasswordResetSuccessful: Subject<boolean> = new BehaviorSubject<boolean>(false);
  statusMessage: Subject<StatusMessage> = new BehaviorSubject<StatusMessage>({title: '', message: ''});

  passwordResetForm = new FormGroup(
    {
      password: new FormControl('', [Validators.required, validPassword]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    { validators: [passwordsMatch] }
  );

  constructor(appComponent: AppComponent, private sftp: SftpProvisioningService) {
    appComponent.pageTitle = 'File Transfer Password Reset'
  }

  @Input() error: Subject<string | null> = new BehaviorSubject<string | null>(
    null
  );

  get passwordControl() {
    return this.passwordResetForm.get('password');
  }

  get isValid() {
    return (
      this.passwordControl?.valid && (this.passwordControl?.dirty || this.passwordControl?.touched)
    );
  }

  get lengthError() {
    return !this.isValid && this.passwordControl?.errors?.minlength;
  }

  get upperCaseError() {
    return !this.isValid && this.passwordControl?.errors?.uppercase;
  }

  get lowerCaseError() {
    return !this.isValid && this.passwordControl?.errors?.lowercase;
  }

  get numberError() {
    return !this.isValid && this.passwordControl?.errors?.digit;
  }

  get specialError() {
    return !this.isValid && this.passwordControl?.errors?.special;
  }

  get confirm() {
    return this.passwordResetForm.get('confirmPassword');
  }

  get mismatchError() {
    return (
      (this.confirm?.dirty || this.confirm?.touched) &&
      this.passwordResetForm.errors?.mismatch
    );
  }

  ngOnInit()
  {
    this.validatePasswordReset();
  }

  validatePasswordReset()
  {
    this.processing.next(true);

    const urlParams = new URLSearchParams(window.location.search);
    const encryptedData = urlParams.get('data');
    const entityId = urlParams.get('entityid');

    if (entityId === null || encryptedData === null) {
      this.processing.next(false);
      this.passwordResetValidated.next(false);
      this.statusMessage.next({title: 'Error', message: 'Password reset request is not valid.'});
      return;
    }

    this.sftp
        .validatePasswordReset({
          body: {
            encryptedQueryStringData: encryptedData!,
            entityId: entityId!,
          },
        })
        .pipe(
          take(1),
          catchError((err) => {
            console.error(err);
            this.processing.next(false);
            this.passwordResetValidated.next(false);
            this.statusMessage.next({title: 'Error', message: 'An unexpected error has occurred.'});
            return of(null);
          })
        )
        .subscribe((response) => {
          if (response === null) {
            console.error('Validate password reset response is null.');

            this.processing.next(false);
            this.passwordResetValidated.next(false);
            this.statusMessage.next({title: 'Error', message: 'An unexpected error has occurred.'});
          }

          let validationMessage: string = '';

          if (!response!.validated) {
            validationMessage = response!.validationErrorMessage !== undefined || response!.validationErrorMessage !== ''
            ? response!.validationErrorMessage!
            : 'Password reset request is not valid.';

            this.processing.next(false);
            this.passwordResetValidated.next(false);
            this.statusMessage.next({title: 'Error', message: validationMessage});

            return;
          }

          this.processing.next(false);
          this.passwordResetValidated.next(true);
          this.showForm.next(true);
        });
  }

  submit() {
    if (this.passwordResetForm.invalid) {
      console.error(this.passwordResetForm.errors);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    let entityId = urlParams.get('entityid');
    let password = this.passwordResetForm.get('password')?.value;

    if (entityId !== null && password !== null) {
      this.sftp
        .passwordReset({
          body: {
            entityId: entityId,
            password: password,
          },
        })
        .pipe(
          take(1),
          catchError((err) => {
            console.error(err);
            this.error.next('An unexpected error has occurred.');
            return of(null);
          })
        )
        .subscribe((response) => {
          if (response === null) {
            this.error.next('An unexpected error has occurred.');
          } else if (response?.result) {
            console.info(response?.message);
            this.showForm.next(false);
            this.showPasswordResetSuccessful.next(true);
          } else {
            console.error(response.message);
            this.error.next('An unexpected error has occurred.');
        }
      });
    }
  }
}

export class StatusMessage {
  title?: string;
  message?: string;
}

// This should be matching what the ValidPasswordAttribute in C# is doing
const validPassword: ValidatorFn = (control: AbstractControl) => {
  const value = control.value;
  if (!value) {
    return null;
  }
  let issues: PasswordValidation = {};
  let wasAnIssue = false;
  if (typeof value !== 'string') {
    issues.type = true;
    wasAnIssue = true;
  }
  if (value.length < 12) {
    issues.minlength = true;
    wasAnIssue = true;
  }
  if (!value.match(/\d/g)) {
    issues.digit = true;
    wasAnIssue = true;
  }
  if (!value.match(/[a-z]/g)) {
    issues.lowercase = true;
    wasAnIssue = true;
  }
  if (!value.match(/[A-Z]/g)) {
    issues.uppercase = true;
    wasAnIssue = true;
  }
  // This must match what the API is doing as to not cause unwanted behavior
  if (
    !value.match(
      /[\~\`\!\@\#\$\%\^\&\*\(\)\-_\+\=\{\}\[\]\|\:\;\'"\<\>\,\.\?\\\/]/g
    )
  ) {
    issues.special = true;
    wasAnIssue = true;
  }
  return wasAnIssue ? issues : null;
};

interface PasswordValidation {
  type?: boolean;
  minlength?: boolean;
  digit?: boolean;
  lowercase?: boolean;
  uppercase?: boolean;
  special?: boolean;
}

const passwordsMatch: ValidatorFn = (control: AbstractControl) => {
  const original = control.get('password')?.value;
  const confirm = control.get('confirmPassword')?.value;
  if (!original || !confirm) {
    return null;
  }
  return original === confirm ? null : { mismatch: true };
};
