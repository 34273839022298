import { ChangeDetectorRef, Component } from '@angular/core';
import { SftpProvisioningService } from './api-client/services';

const REGISTRATION_PATH = '/registration';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  crYear: number = new Date().getFullYear();
  pageTitle: string = 'File Transfer Registration';

  constructor() {}
}
