import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';
import { SftpProvisioningService } from 'src/app/api-client/services';

const REGISTRATION_PATH = '/registration';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  crYear: number = new Date().getFullYear();
  formStatus: Subject<string> = new BehaviorSubject<string>('loading');
  passwordReset: Subject<boolean> = new BehaviorSubject<boolean>(false);

  error: Subject<string | null> = new BehaviorSubject<string | null>(null);

  // Returned back from initial api
  entityId: Subject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);

  passwordSent: Subject<boolean> = new BehaviorSubject<boolean>(false);
  pinVerified: Subject<boolean> = new BehaviorSubject<boolean>(false);

  // Using this pattern so we can detect right when our component can see the child component
  private _hangTight: Subject<MatStep> = new Subject();
  @ViewChild('hangTight')
  set hangTight(comp: MatStep | undefined) {
    if (comp) {
      this._hangTight.next(comp);
    }
  }

  @ViewChild('stepper') stepper: MatStepper | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private sftp: SftpProvisioningService
  ) {}

  ngOnInit() {
    const loc = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const encryption = urlParams.get('data');
    const entityId = urlParams.get('entityid');
    if (
      loc.startsWith(REGISTRATION_PATH) &&
      entityId !== null &&
      encryption !== null
    ) {
      this.passwordReset.next(urlParams.get('reset') === '1');
      this.sftp
        .validate({
          body: {
            encryptedQueryStringData: encryption,
            entityId: entityId,
          },
        })
        .pipe(
          take(1),
          catchError((err) => {
            console.error(err);
            this.formStatus.next('error');
            return of(null);
          })
        )
        .subscribe((response) => {
          if (response === null) {
            return;
          }
          if (response.validated) {
            if (
              response.provisioningStatus?.toLowerCase() ===
              'provisioncompleted'
            ) {
              this.formStatus.next('completed');
            } else {
              this.formStatus.next('valid');
              if (
                response.provisioningStatus?.toLowerCase() === 'addedtoqueue'
              ) {
                this.alreadyCompleted();
              } else if (!response.hasPin) {
                this.skipPinStep(response.entityId);
              }
            }
            this.entityId.next(response.entityId);
          } else {
            this.formStatus.next('notFound');
            window.history.replaceState({}, document.title, '/');
          }
        });
    } else {
      this.formStatus.next('notFound');
      window.history.replaceState({}, document.title, '/');
    }
  }

  onVerifyPin(pin: string) {
    this.entityId
      .pipe(
        concatMap((entityId) => {
          return this.sftp.twoFactorCheck({
            body: {
              pin,
              entityId: entityId === null ? undefined : entityId,
            },
          });
        }),
        take(1),
        catchError((err) => {
          console.error(err);
          this.error.next('Invalid PIN entered. Please try again.');
          return of(null);
        })
      )
      .subscribe((resp) => {
        if (resp === null) {
          return;
        }
        this.error.next(null);
        this.pinVerified.next(true);
        this.cdr.detectChanges();
        this.stepper?.next();
      });
  }

  onSendPassword(password: string) {
    combineLatest([this.entityId, this.passwordReset])
      .pipe(
        concatMap(([entityId, passwordReset]) => {
          return this.sftp.complete({
            body: {
              password: password,
              entityId: entityId === null ? undefined : entityId,
            },
          });
        }),
        take(1),
        catchError((err) => {
          console.error(err);
          this.error.next('Could not save password.');
          return of(null);
        })
      )
      .subscribe((resp) => {
        if (resp === null) {
          return;
        }
        this.error.next(null);
        this.passwordSent.next(true);
        this.cdr.detectChanges();
        this.stepper?.next();
      });
  }

  // Call this method if the initial API tells us that they are already completed
  private alreadyCompleted() {
    this.pinVerified.next(true);
    this.passwordSent.next(true);
    this._hangTight.pipe(take(1)).subscribe((hangTight) => {
      hangTight.select();
    });
  }

  private skipPinStep(id: string | undefined) {
    this.entityId.next(id);
    this.onVerifyPin('0000');
  }
}
