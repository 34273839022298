import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-last',
  templateUrl: './last.component.html',
  styleUrls: ['./last.component.scss'],
})
export class LastComponent {
  @Input() passwordReset: boolean | null = false;

  constructor() {}
}
