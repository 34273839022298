import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith(`${environment.apiUrl}/`)) {
      return next.handle(request);
    }

    let headers = request.headers.set('Content-Type', 'application/json');
    const functionKey: string | undefined = environment.apiKey;
    if (functionKey) {
      headers = headers.set('x-functions-key', functionKey);
    }
    return next.handle(request.clone({ headers }));
  }
}
