<div class="content">
  <header>
    <img src="/assets/img/synergi-logo.png" />
    <span class="spacer"> </span>
    <h1>{{pageTitle}}</h1>
    <span class="spacer"></span>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>
    <span class="spacer"></span>
    <div>
      <p>
        US: <a href="tel:843-519-0808">(843) 519-0808</a>&nbsp;&nbsp; PR:
        <a href="tele:787-520-7503">(787) 520-7503</a><br />
        <a href="mailto:CARESOnboardingSupport@synergipartners.com"
          >CARESOnboardingSupport@synergipartners.com</a
        >
      </p>
      <p>&copy; {{ crYear }} Synergi Partners</p>
    </div>
    <span class="spacer"></span>
  </footer>
</div>
